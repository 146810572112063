import React from 'react'
import { Link } from 'gatsby'

const Nav = ({ closeMenu, classNameBlog, className }) => {
  const links = [
    { name: 'Articles', href: '/articles', isBlog: true },
    { name: 'Projects', href: '/projects' },
    { name: 'Talks', href: '/talks' },
    { name: 'Podcast', href: '/podcast' },
    { name: 'Shop', href: '/shop' },
    { name: 'Profile', href: '/profile' },
  ]

  return (
    <>
      {links.map((link) => (
        <Link
          key={link.name}
          onClick={closeMenu}
          getProps={link.isBlog ? classNameBlog : className}
          to={link.href}
        >
          {link.name}
        </Link>
      ))}
    </>
  )
}

export default Nav
