import React from 'react'

const HamburgerMenu = ({ opened, setOpened }) => {
  return (
    <button
      type="button"
      className="inline-flex lg:hidden items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-primary-500"
      aria-controls="mobile-menu"
      onClick={() => setOpened(!opened)}
    >
      <span className="sr-only">Open main menu</span>

      <svg
        className={`h-6 w-6 ${opened ? 'hidden' : ''}`}
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
        aria-hidden="true"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M4 6h16M4 12h16M4 18h16"
        />
      </svg>

      <svg
        className={`h-6 w-6 ${!opened ? 'hidden' : ''}`}
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
        aria-hidden="true"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M6 18L18 6M6 6l12 12"
        />
      </svg>
    </button>
  )
}

export default HamburgerMenu
