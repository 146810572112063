import React, { useState } from 'react'
import { Link } from 'gatsby'
import HamburgerMenu from './HamburgerMenu'
import Nav from './Nav'

const Header = ({ isBlog }) => {
  const [opened, setOpened] = useState(false)
  const closeMenu = () => setOpened(false)

  const activeClassName = (isActive) => {
    const classes =
      'header-link hover:text-primary-500 relative font-semibold whitespace-nowrap'
    const activeClasses = isActive ? 'active text-primary-500' : 'text-gray-900'

    return { className: [classes, activeClasses].join(' ') }
  }

  const className = ({ isPartiallyCurrent }) =>
    activeClassName(isPartiallyCurrent)

  const classNameBlog = ({ isPartiallyCurrent }) =>
    activeClassName(isBlog || isPartiallyCurrent)

  return (
    <header className="px-3">
      <nav className="container max-w-6xl text-gray">
        <div className="relative -my-2px py-4 flex flex-wrap justify-between items-baseline">
          <Link
            onClick={closeMenu}
            className="mr-4 text-2xl text-gray-500 hover:no-underline shrink-0 font-normal"
            to="/"
          >
            Guillaume <span className="font-extrabold text-black">BRIDAY</span>
          </Link>

          <HamburgerMenu opened={opened} setOpened={setOpened} />

          <div className="hidden lg:flex items-baseline gap-8">
            <Nav
              closeMenu={closeMenu}
              classNameBlog={classNameBlog}
              className={className}
            />
          </div>

          <div
            className={`absolute lg:hidden bg-white rounded-xl z-10 shadow-xl ring-1 ring-gray-200 w-full top-16 p-4 ${
              !opened ? 'hidden' : ''
            }`}
          >
            <div
              id="mobile-menu"
              className="flex flex-col gap-4 items-baseline"
            >
              <Nav
                closeMenu={closeMenu}
                classNameBlog={classNameBlog}
                className={className}
              />
            </div>
          </div>
        </div>
      </nav>
    </header>
  )
}

export default Header
